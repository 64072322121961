var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "c-container",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ]
    },
    [
      _c(
        "c-box",
        { attrs: { flex: "row" } },
        [
          _c(
            "c-box",
            { attrs: { grow: "1" } },
            [
              _c(
                "c-box",
                {
                  attrs: {
                    flex: "row",
                    justify: "flex-start",
                    align: "flex-start"
                  }
                },
                [
                  _c("c-title", { staticStyle: { "margin-right": "20px" } }, [
                    _vm._v("位置预览")
                  ]),
                  _c("CheckFiles", {
                    ref: "CheckFiles",
                    attrs: { fileTypes: ["jpg", "png", "jpeg"] },
                    on: { error: _vm.onerror, submit: _vm.onsubmit }
                  }),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "permission",
                          rawName: "v-permission",
                          value: [74],
                          expression: "[74]"
                        }
                      ],
                      attrs: { type: "primary", size: "small" },
                      on: {
                        click: function($event) {
                          _vm.$refs.CheckFiles &&
                            _vm.$refs.CheckFiles.chooseFiles()
                        }
                      }
                    },
                    [_vm._v(" 上传平面图 ")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "warning", size: "small" },
                      on: { click: _vm.handleReRender }
                    },
                    [_vm._v(" 重新渲染 ")]
                  )
                ],
                1
              ),
              _c("plan", {
                ref: "plan",
                attrs: {
                  width: "100%",
                  height: "518px",
                  tooltips: { width: 100, trigger: "select" },
                  editIdx: _vm.editIdx,
                  hover: true,
                  select: _vm.createable && !_vm.editable,
                  bgImg: ""
                },
                on: {
                  hover: _vm.onHover,
                  select: _vm.onSelect,
                  drag: _vm.onDrag,
                  move: _vm.onMove
                },
                scopedSlots: _vm._u([
                  {
                    key: "box",
                    fn: function(scope) {
                      return [
                        _c(
                          "c-box",
                          {
                            attrs: {
                              color: "#fff",
                              height: "100%",
                              size: "20px",
                              flex: "row",
                              align: "center"
                            }
                          },
                          [_vm._v(" " + _vm._s(scope.data.shelfNo) + " ")]
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _c(
            "c-box",
            { attrs: { width: "27%", padding: "0 0 0 10px" } },
            [
              _c(
                "c-box",
                {
                  attrs: {
                    flex: "row",
                    justify: "space-between",
                    align: "flex-start"
                  }
                },
                [
                  _c("c-title", [_vm._v("操作区")]),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "permission",
                          rawName: "v-permission",
                          value: [73],
                          expression: "[73]"
                        }
                      ],
                      attrs: {
                        type: "primary",
                        disabled: !_vm.createable || _vm.editable,
                        size: "mini"
                      },
                      on: { click: _vm.handleCreate }
                    },
                    [_vm._v(" 新增 ")]
                  )
                ],
                1
              ),
              _vm.list[_vm.selectIdx]
                ? _c(
                    "el-form",
                    {
                      ref: "form",
                      attrs: {
                        model: _vm.list[_vm.selectIdx],
                        size: "small",
                        "label-width": "100px"
                      },
                      nativeOn: {
                        submit: function($event) {
                          $event.preventDefault()
                          return _vm.submit($event)
                        }
                      }
                    },
                    [
                      _c("c-form-row", {
                        attrs: { list: _vm.models },
                        model: {
                          value: _vm.list[_vm.selectIdx],
                          callback: function($$v) {
                            _vm.$set(_vm.list, _vm.selectIdx, $$v)
                          },
                          expression: "list[selectIdx]"
                        }
                      }),
                      _c(
                        "c-box",
                        { attrs: { margin: "0 0 10px 0" } },
                        [
                          _vm.editable
                            ? _c(
                                "el-button",
                                {
                                  attrs: { size: "mini", type: "primary" },
                                  on: { click: _vm.handleAddLevel }
                                },
                                [_vm._v(" 新增一层 ")]
                              )
                            : [
                                _c(
                                  "c-box",
                                  {
                                    directives: [
                                      {
                                        name: "permission",
                                        rawName: "v-permission",
                                        value: [75],
                                        expression: "[75]"
                                      }
                                    ],
                                    attrs: {
                                      flex: "row",
                                      justify: "flex-start",
                                      align: "center"
                                    }
                                  },
                                  [
                                    _c(
                                      "c-box",
                                      { attrs: { padding: "10px" } },
                                      [_vm._v("状态：")]
                                    ),
                                    _c("c-switch", {
                                      attrs: {
                                        value: _vm.list[_vm.selectIdx].status
                                      },
                                      on: { change: _vm.changeStatus }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "c-box",
                                  {
                                    directives: [
                                      {
                                        name: "permission",
                                        rawName: "v-permission",
                                        value: [74],
                                        expression: "[74]"
                                      }
                                    ],
                                    attrs: { padding: "10px" }
                                  },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          size: "mini",
                                          type: "primary"
                                        },
                                        on: { click: _vm.handleEdit }
                                      },
                                      [_vm._v(" 编 辑 ")]
                                    ),
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { size: "mini", type: "danger" },
                                        on: { click: _vm.handleDel }
                                      },
                                      [_vm._v(" 删 除 ")]
                                    )
                                  ],
                                  1
                                )
                              ],
                          _c("c-box", { attrs: { height: "10px" } }),
                          _c("c-table", {
                            attrs: {
                              columns: _vm.columns,
                              data: _vm.levelList,
                              border: "",
                              size: "mini",
                              stripe: "",
                              height: "auto"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "btns",
                                  fn: function(scope) {
                                    return [
                                      _c(
                                        "el-link",
                                        {
                                          attrs: {
                                            type: "danger",
                                            disabled:
                                              scope.$index <
                                              _vm.levelList.length - 1,
                                            underline: false
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.handleDelLevel(
                                                scope.$index
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v(" 删除 ")]
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              37845943
                            )
                          })
                        ],
                        2
                      ),
                      _vm.editable
                        ? _c(
                            "el-form-item",
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "primary" },
                                  on: { click: _vm.submit }
                                },
                                [_vm._v(" 保 存 ")]
                              ),
                              _c(
                                "el-button",
                                { on: { click: _vm.handleCancel } },
                                [_vm._v(" 取 消 ")]
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }