import _objectSpread from "/Users/learning/Documents/workspace/kindstar/customer-orders/node_modules/@babel/runtime/helpers/esm/objectSpread2";import _objectWithoutProperties from "/Users/learning/Documents/workspace/kindstar/customer-orders/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties"; /*
                                                                                                                                                                                                                                                                                                                     * @Description:
                                                                                                                                                                                                                                                                                                                     * @Version: 1.0
                                                                                                                                                                                                                                                                                                                     * @Autor: silencc
                                                                                                                                                                                                                                                                                                                     * @Date: 2020-11-13 14:58:21
                                                                                                                                                                                                                                                                                                                     * @LastEditors: silencc
                                                                                                                                                                                                                                                                                                                     * @LastEditTime: 2020-11-16 14:34:54
                                                                                                                                                                                                                                                                                                                     */

// import { parseTime } from '@/plugins/lib/modules/date'
// import { remit, getType } from '@/utils/options'

export function transferBox(_ref) {var abscissa = _ref.abscissa,ordinate = _ref.ordinate,width = _ref.width,height = _ref.height,rest = _objectWithoutProperties(_ref, ["abscissa", "ordinate", "width", "height"]);
  return _objectSpread({
    x: abscissa,
    y: ordinate,
    w: width,
    h: height },
  rest);

}

export function retransferBox(_ref2) {var x = _ref2.x,y = _ref2.y,w = _ref2.w,h = _ref2.h,rest = _objectWithoutProperties(_ref2, ["x", "y", "w", "h"]);
  return _objectSpread({
    abscissa: x,
    ordinate: y,
    width: w,
    height: h },
  rest);

}


export var columns = function columns(ctx) {return [
  {
    label: '层数',
    type: 'index' },

  {
    label: '货位编码',
    kname: 'locationNo' },

  {
    label: '操作',
    kname: 'btns',
    width: 84,
    showFn: function showFn() {
      return ctx.editable;
    } }];};



/**
             * ruleTypes: 规则类型
             * genRules： rule 生成函数
             * ctx： Vue实例
             */
import { genModels } from '@/plugins/widget/c-form/rules';

export var models = genModels(function (genRules, ctx) {return [
  {
    kname: 'shelfNo',
    itemProps: {
      label: '货架编号：' },

    component: {
      name: 'text' } },


  {
    kname: 'depotId',
    itemProps: {
      label: '所属货区：',
      rules: [
      genRules('require')] },


    component: {
      name: 'selector',
      always: true,
      readOnlyFn: function readOnlyFn() {
        return !ctx.editable;
      },
      props: {
        type: 'depots' } } },



  {
    span: 12,
    kname: 'w',
    itemProps: {
      label: '货架宽：' },

    component: {
      name: 'text' } },


  {
    span: 12,
    kname: 'h',
    itemProps: {
      label: '货架高：' },

    component: {
      name: 'text' } },


  {
    span: 12,
    kname: 'x',
    itemProps: {
      label: 'X坐标：' },

    component: {
      name: 'text' } },


  {
    span: 12,
    kname: 'y',
    itemProps: {
      label: 'Y坐标：' },

    component: {
      name: 'text' } }];});





// form 默认值
export var formData = function formData() {return {};};